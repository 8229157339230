<template lang="pug">
.cont
  svg.group-hover_fill-fillTrash.transition-colors.ease-in-out.duration-200(
    width='24',
    height='24',
    xmlns='http://www.w3.org/2000/svg',
    fill='#91B4E7'
  )
    path(
      fill-rule='evenodd',
      clip-rule='evenodd',
      d='M9 5H6v2h12V5h-3l-1-1h-4L9 5zm8 3H7v10a2 2 0 002 2h6a2 2 0 002-2V8z'
    )
  skif-badge(list, :value='arrayToDelete.length')
</template>
<script>
export default {
  props: ['arrayToDelete'],
}
</script>